import React from "react"
import { Header, Container } from "semantic-ui-react";
import { MainHeader } from "../helpers/styledComponents";
import styled from "styled-components";
import Layout from "../components/Layout";
import OneTrustContent from "../components/OneTrustContent";
import { Helmet } from "react-helmet";

const PageContainer = styled(Container)`
`;

export default ()=>(
  <Layout>
    <Helmet>
      <title>Terms of Use | Full Story Initiative</title>
      <meta name="title" content="Terms of Use | Full Story Initiative" />
      <meta name="og:title" content="Terms of Use | Full Story Initiative" />
      <meta name="twitter:title" content="Terms of Use | Full Story Initiative" />
      <meta name="og:site_name" content="Terms of Use | Full Story Initiative" />
    </Helmet>
    <PageContainer>
      <MainHeader>
        <Header className="h1 compact" content="Terms of Use" />
      </MainHeader>
      <OneTrustContent 
        id="otnotice-02b459e2-3993-4573-85bf-0b4e326494a4"
        url="https://privacyportal-cdn.onetrust.com/722df920-f42e-4d91-b843-6306f9a6a5a0/privacy-notices/02b459e2-3993-4573-85bf-0b4e326494a4.json"
      />
    </PageContainer>
  </Layout>
);
