import React, { useEffect } from "react"
interface IProps {
  id: string;
  url: string;
}

export default ({
  id,
  url
}:IProps) => {
  let interval:any;
  useEffect(() => {
    //interval is needed to check when OneTrust api become available via Window
    interval = setInterval(()=>{
      if(window.OneTrust){
        window.OneTrust.NoticeApi.Initialized.then(function () {
          window.OneTrust.NoticeApi.LoadNotices([ url ]);
        });
        clearInterval(interval);
      }
    }, 200);
  }, [url])

  return (
    <div id={id} className="otnotice">
      <div style={{textAlign: "center"}}>
        ...loading
      </div>
    </div>
)
}